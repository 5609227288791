import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from "../components/layout";
import SEO from "../components/seo";
import BlogPosts from "../components/blogPosts";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout location={props.location} title={`Erik van Lennep`} mdxType="Layout">
  <SEO title="Sustainable Innovation Coach and Consultant." keywords={[`sustainability`, `biomimicry`, `biochar`, `climate action`, `regenerative culture`, `regenerative agriculuture`]} mdxType="SEO" />
      <p>{`  Hey there! I am Erik.`}</p>
      <p>{`  I train, consult and coach on topics around Sustainable Innovation. I also host the `}<a parentName="p" {...{
          "href": "https://rasa.ag/designers/"
        }}><em parentName="a">{`Designers of Paradise`}</em>{` podcast`}</a>{` on regenerative culture. I can help you design and align your sustainability projects and team for optimum impact.`}</p>
      <p>{`  I started this blog as a toolbox of ideas I feel are important for the ‘Re’ agenda of our times: Rethinking, Reconnection, Regeneration and Renewal.`}</p>
      <p>{`  Let’s chat via `}<a parentName="p" {...{
          "href": "https://twitter.com/Green_Heart"
        }}>{`Twitter`}</a>{` or `}<a parentName="p" {...{
          "href": "https://www.linkedin.com/in/erikvanlennep/"
        }}>{`LinkedIn`}</a></p>
  <BlogPosts mdxType="BlogPosts"></BlogPosts>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      